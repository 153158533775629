/* Confidence distribution histogram height */
.confidence-distribution {
  div:first-child {
      height: 17.5vh;
    }
}


/* Confusion matrix height */
#cmPlotDiv{
  height: 35vh;
}
