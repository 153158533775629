/* Basic font sizes */
h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

body {
  font-size: 15px;
}

/* Alerts */
#messages {
  z-index: 1080;
}

.alert-debug {
  color: $custom-black;
  background-color: $custom-white;
  border-color: $custom-grey-dark-darken;
}

.alert-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

/* Remove default link styles to wrap link arround other elements */
.blank-link {
  text-decoration: none;
  color: inherit;
}

.deactivate-link-button {
  pointer-events: none;
  opacity: 0.6;
}

.section-title + .section-subtitle,
.section-title + .section-content,
.section-content + .section-content {
  margin-top: 1.5rem;
}

[hx-get][hx-trigger="click"] {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.img-crop-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
}

.img-crop-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.plotly-graph-div {
  overflow: hidden;
}

.img-overlay-text {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: white;
  color: black;
  border-radius: 3px;
}

/* Default font size */
.fs-default {
  font-size: 15px;
}

/* Slim inline crispy forms */
.slim-inline-form {
  .mt-3 {
    margin-top: 0 !important;
  }
  .mb-3 {
    margin-bottom: 0 !important;
  }
}

/* Viewport min height for main content */
.mh-90 {
  min-height: 90vh;
}
