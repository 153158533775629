.confidence-wrapper {
  position: relative;
}

.outer-ref {
  container-type: inline-size;
  position: relative;
}

.confidence-bar-padding {
  overflow: hidden;
  padding: 0.2vw;
  border-radius: 1.1vw;
  background-color: #e9ecef;
}

.confidence-custom {
  overflow: visible;
  border-radius: 0.9vw;
  position: relative;
  height: 1.5vw; /* Actual progress bar height */
  min-height: 25px;
}

.progress-bar {
  font-size: 1rem;
  z-index: 1;
  position: relative;
  border-radius: 1vw; /* Rounded corners for the progress bar */
}

@container (max-width: 300px) {
  .progress-bar {
    font-size: 0.6vw;
  }

  .confidence-bar-padding {
    padding: 0.1vw;
  }

  .confidence-custom {
    height: 1.2vw;
  }
}

.confidence-min-max {
  position: absolute;
  width: 2px; /* Width of the min/max lines */
  border: 2px;
  border-radius: 2px;
  height: 2.2vw; /* Match progress bar  + 2xexternal padding */
  top: -0.2vw; /* -external padding */
  background-color: red; /* Color of the min/max lines */
  z-index: 20;
  min-height: 30px;
}

.confidence-label {
  position: absolute;
  top: -1.2em; /* Position above the progress bar */
  font-size: 1em;
  white-space: nowrap;
  z-index: 20;
}
